import React from 'react';
import style from './404.module.scss';
import { Link } from 'gatsby';
import BetterHeader from '../components/header/betterHeader';

export default function notFound({}) {
  return (
    <div>
      <BetterHeader />
      <div className={`${style.wrapper}`}>
        <article>
          <h1>We can't find the page you are looking for...</h1>
          <p className={`${style.errorCode}`}>Error: 404</p>
          <p className={`${style.linkLabel}`}>
            Here are a few links that may be helpful:
          </p>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about/">About Centeva</Link>
            </li>
            <li>
              <Link to="/contact/">Contact Us</Link>
            </li>
          </ul>
        </article>
      </div>
    </div>
  );
}
